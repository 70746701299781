<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3>
      <div class="card-toolbar">
        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="createApiKeyClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.APIKEYS.NEW')}}</a>
      </div>
    </div>
    <div class="card-header border-0 py-5 m-1">
      <div>
        <b-form-select v-model="perPage" :options="options"></b-form-select>
      </div>
      <b-pagination
        class="ml-auto mb-0"
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        aria-controls="apikeys-table"
        first-number
        last-number
      ></b-pagination>
    </div>
    <div class="card-body pt-0">
      <b-table
        id="apikeys-table"
        :fields="headers"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        head-variant="light"

      >
        <template #cell(service)='row'>
          {{ serviceName[row.item.service] }}
        </template>

        <template #cell(status)='row'>
          {{ statusName[row.item.status] }}
        </template>

        <template #cell(id)="row">
          <div class='d-flex justify-content-end'>
            <a
              href="#"
              class="button"
              @click="selectApiKeyClicked(row.item.id)"
            >
            {{$t('COMMON.MANAGE')}} >
            </a>


            <a
              href="#"
              class="button ml-8"
              style="color: rgb(255 27 27);"
              @click="deleteApiKeyClicked(row.item.id)"
            >
            {{$t('COMMON.DELETE')}} >
            </a>

          </div>
        </template>
      </b-table>
    </div>
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'apikey-table',
  props: ['items', 'currentCompanyId'],
  emits: ['createApiKeyClicked', 'selectApiKeyClicked', 'deleteApiKeyClicked'],
  components: {

  },
  mixins: [ toasts ],
  watch: {

  },
  mounted() {

  },
  methods: {
    createApiKeyClicked() {
      this.$emit('createApiKeyClicked');
    },
    selectApiKeyClicked(id) {
      this.$emit('selectApiKeyClicked', id);
    },
    deleteApiKeyClicked(id) {
      this.$emit('deleteApiKeyClicked', id);
    },
  },

  data() {
    return {
      serviceName: {
        'DINTERO': 'Dintero',
        'FORTNOX': 'Fortnox',
        'SPAR': 'SPAR',
        'TWILIO': 'Twilio',
        'SINCH': 'Sinch',
        'STRIPE': 'Stripe'
      },
      statusName: {
        'CLAIMED': this.$t('COMMON.OK'),
        'UNCLAIMED': 'Ange nyckel',
        'CREATED': this.$t('COMMON.OK'),
        'REVOKED': 'Spärrat',
        '': this.$t('COMMON.OK')
      },
      options: [
        { value: 100 , text: '100'},
        { value: 150 , text: '150'},
        { value: 200 , text: '200'}
      ],
      apiKeys: [],
      perPage: 100,
      currentPage: 1,
      headers: [
        {
          key: 'company.name',
          label: this.$t('COMPANY.COMPANY'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'service',
          label: this.$t('PAGES.APIKEYS.SERVICE'),
          sortable: true
        },
        {
          key: 'public_token',
          label: this.$t('PAGES.APIKEYS.PUBLIC_TOKEN'),
          sortable: true
        },
        {
          key: 'status',
          label: this.$t('COMMON.STATUS'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
        },
        {
          key: 'id',
          label: this.$t('COMMON.MANAGE'),
          thClass: 'd-flex flex-row-reverse pr-6'
        },
      ],
      status: {
        UNCLAIMED : 'VÄNTAR',
        CLAIMED: 'AKTIV',
        CREATED  : 'SKAPAD',
        REVOKED : 'SPÄRRAT'
      }
    };
  }
};
</script>
